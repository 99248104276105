.profile-log-out-section {}

.profile-log-out-details {}

.LogOut-Image-main-con {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Logout-image {
    /* border: 1px solid blue; */
    height: 20vh;
    width: 20vh;
    margin-bottom: 30px;
}

.Logout-image img {
    height: 100%;
    width: 100%;
}

.profile-logout-text-details {
    margin-bottom: 15px;
}

.profile-logout-text-details p {
    margin: 0px;
    font-family: var(---font01);
    font-size: 22px;
    font-weight: 500;
}

.profile-logout-btn {}

.profile-logout-btn button {
    border: none;
    font-family: var(---font01);
    font-size: 16px;
    padding: 10px 30px;
    background-color: black;
    border: 2px solid black;
    color: white;
}

.profile-logout-btn button:hover{
    color: black;
    background-color: white;
    border: 2px solid black;
}