
/* //////////////////////////// Section 2 //////////////////////////////// */

.contact-page-section-2{
    /* border: 1px solid blue; */
    padding: 10vh 0px;
    background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url("../../Images/Home/what-is-market-volatility.webp");
    background-size: cover;
}

.contact-page-section-2-form-con{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-page-section-2-form{
    /* border: 1px solid green; */
    width: 45%;
}

.contact-page-section-2-heading{
    /* border: 1px solid hotpink; */
}

.contact-page-section-2-heading h2{
    color: white;
    font-family: var(---font01);
    font-size: 40px;
}

.contact-page-section-2-heading h3{
    font-family: var(---font01);
    font-size: 15px;
    margin: 20px 0px;
    color: white;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-input-outlined:focus, :where(.css-dev-only-do-not-override-1okl62o).ant-input-outlined:focus-within {
    border-color: black;
    box-shadow: none;
    outline: 0;
    background-color: #ffffff;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-input {
    border-radius: 0px;
    height: 40px;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-input-outlined:hover {
    border-color: var(---colorG);
    background-color: #ffffff;
}

.contact-page-sumbit-btn{

}

.contact-page-sumbit-btn button{
    border-radius: 0px;
    width: 100%;
    height: 50px;
    font-family: var(---font01);
    font-size: 15px;
    color: white;
    background-color: var(---colorG);
    border: 1px solid var(---colorG);
}



:where(.css-dev-only-do-not-override-1okl62o).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #d9d9d9;
    border-color: var(---colorG);
    background: var(---colorG);
}

.contact-page-section-2-main-form p{
    /* border: 1px solid blue; */
    font-size: 11px;
    font-family: var(---font01);
    font-style: italic;
    color: white;
}

.contact-page-section-2-main-form input{
    height: 50px;
}