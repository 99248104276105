/*** 
=============================================
    Testimonial Area style
=============================================
***/
.testimonial-area {
    padding: 80px 0 80px;
    background: whitesmoke;
}

.testimonial-area .left-content .sec-title {
    padding-bottom: 15px;
}

.testimonial-area .left-content .text-holder a {
    margin-top: 20px;

}

.testimonial-area .left-content .text-holder a:hover {
    background: var(---colorG);
    color: #ffffff;
}

.testimonial-area .single-item {
    position: relative;
    display: block;
    overflow: hidden;
}

.testimonial-area .single-item .img-holder {
    margin-right: 33px;
    padding: 9px;
    border: 1px solid #ebf8ff;
    border-radius: 4px;
}

.testimonial-area .single-item:hover .img-holder {}

.testimonial-area .single-item .img-holder .client-name {
    padding-top: 17px;
    padding-bottom: 3px;
}

.testimonial-area .single-item .img-holder .client-name h3 {
    margin: 0 0 4px;
}

.testimonial-area .single-item .img-holder .client-name p {
    color: var(---colorG);
    margin: 0;
}

.testimonial-area .single-item .img-holder img {
    height: 100%;
    width: 100%;
}

.testimonial-area .single-item .text-holder {
    transition: all 0.7s ease;
    /* margin-left: -33px; */
    text-align: left;
    padding: 10px 0px 0px 15px;
}

.testimonial-area .single-item .text-holder h3 {
    font-family: var(---font01);
    font-size: 25px;
}

.testimonial-area .single-item .text-holder p {
    font-family: var(---font01);
    font-size: 15px;
}

.testimonial-area .single-item .text-holder .quote-icon {
    margin-bottom: 12px;
    margin-top: -2px;
    height: 60px;
    width: 60px;
}

.testimonial-area .single-item .text-holder .quote-icon img {
    height: 100%;
    width: 100%;
}

.testimonial-area .single-item .text-holder .quote-icon i {
    font-size: 60px;
    line-height: 60px;
    color: #ebf8ff;
}

.testimonial-area .single-item .text-holder h3 {
    margin: 0 0 14px;
}

.testimonial-area .single-item .text-holder .review-box {
    overflow: hidden;
    margin-top: 10px;
}

.testimonial-area .owl-theme .owl-dots {
    display: none;
    text-align: center;
}

.testimonial-area .owl-theme .owl-nav {
    left: 44%;
    position: absolute;
    width: 10px;
    bottom: 30px;
}

.testimonial-area .owl-theme .owl-nav .owl-prev {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #e0edf4;
    display: block;
    height: 40px;
    left: -44px;
    position: absolute;
    top: -15px;
    transition: all 700ms ease 0s;
    width: 40px;
    z-index: 10;
}

.testimonial-area .owl-theme .owl-nav .owl-next {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #e0edf4;
    display: block;
    height: 40px;
    position: absolute;
    right: -44px;
    top: -15px;
    width: 40px;
    z-index: 10;
    transition: all 700ms ease 0s;
}

.testimonial-area .owl-theme .owl-nav .owl-prev:hover {
    background: #209fd6;
    border-color: #209fd6;
}

.testimonial-area .owl-theme .owl-nav .owl-next:hover {
    background: #209fd6;
    border-color: #209fd6;
}

.testimonial-area .owl-theme .owl-nav div .fa:hover {
    color: #fff;
}

.testimonial-area .owl-theme .owl-nav div .fa {
    color: #ececec;
    font-size: 25px;
    display: block;
    padding-top: 2px;
    text-align: center;
}

.img-holder .client-name {
    font-family: var(---font01);
}

.review-box {}

.review-box svg {
    color: var(---colorG);
}

.left-content {
    text-align: left;
}


.owl-stage .swiper-slide {
    padding-bottom: 40px;
}

.Head-Style-Service {
    font-family: var(---font01);
    font-weight: 600;
    font-size: 40px;
}