.Home_Section-01{
    /* border: 1px solid blue; */
    height: 86vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)),url("../Images/Banner/home\ banner.avif");
    background-size: cover;
}

.Home-Section-1-Banner-con{
    /* border: 1px solid red; */
    height: 100%;
}

.home-section-banner-details-con{
    /* border: 1px solid green; */
    height: 85vh;
    display: flex;
    align-items: center;
}

.home-section-banner-details{
    /* border: 1px solid red; */
    text-align: left;
    width: 80%;
    margin: 0px 0px 0px 30px;
}

.home-section-banner-details h2{
    font-family: var(---font01);
    text-transform: capitalize;
    font-weight: 300;
    font-size: 50px;
    color: white;
}

.home-section-banner-details h2 span{
color: var(---colorG);
}

.home-section-banner-details p{
    font-family: var(---font01);
    font-size: 16px;
    color: white;
}

.Home-Section-banner-Image-con{
    /* border: 2px solid blue; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home-section-banner-image{
    /* border: 1px solid red; */
    height: auto;
    width: 80%;
}

.Home-section-banner-image img{
    height: 100%;
    width: 100%;
}

.home-section-banner-btn-con{
    /* border: 1px solid blue; */
    margin: 30px 0px 0px 0px;
}

.home-section-banner-btn-con button{
    border:none;
    padding: 15px 40px;
    background-color: var(---colorG);
    color: white;
    font-family: var(---font01);
    font-size:15px ;
}