.login-page {
    padding: 10vh 0px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(219 119 27);
}

.Register-main-con {
    /* border: 1px solid blue; */
    padding: 0px 30px;
    width: 100%;
}

.Register-con {
    /* border: 1px solid red; */
}

.account-card {
    width: 100%;
    background-color: white;
    /* max-width: 400px; */
    /* box-shadow: 2px 3px 6px #808080; */
    padding: 20px 40px;
    /* border-radius: 10px; */
}

.account-card.signUp {
    /* max-width: 400px; */
}

.account-card .title {
    margin: 25px 0 25px 0;
}

.account-card .title h1 {
    color: var(--primeColor);
    font-size: 2rem;
}

.account-card input {
    background-color: transparent !important;
}

.account-card input:hover {
    border-color: #000 !important;
}

.account-card .login-form-button {
    width: 100%;
    height: 40px;
    background-color: black;
    color: #fff;
    margin-bottom: 15px;
}

.account-card .login-form-button :hover {
    /* color: black !important; */
    /* border-color: white !important;
    background-color: white !important;
    border: 1px solid black !important; */
}

.account-card a {
    font-size: 15px;
    color: var(---FontColor01);
    text-decoration: none !important;
    font-weight: 500;
}

.widthimage {
    width: 100%;
}

.Google-Login-btn-con {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Google-Login-btn-main-con {
    /* border: 1px solid green; */
    display: flex;
    align-items: center;
    padding: 8px 30px;
    border-radius: 50px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
}

.Google-Login-icon {
    /* border: 1px solid red; */
    height: 30px;
    width: 30px;
}

.Google-Login-icon img {
    height: 100%;
    width: 100%;
}

.Google-Login-text {
    /* border: 1px solid blue; */
    margin: 0px 0px 0px 10px;
}

.Google-Login-text h3 {
    font-size: 15px;
    margin: 0px;
    color: black;
    font-family: var(---font01);
}

.Google-Login-btn-con:hover {
    cursor: pointer;
}

.Login-images-main-con {
    /* border: 1px solid red; */
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("../Images/Login/background_login.jpeg");
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Login-image-logo-con {
    /* border: 1px solid blue; */
    height: 170px;
    width: auto;
}

.Login-image-logo-con img {
    height: 100%;
    width: 100%;
}

/* otp css  */
.SignUp-section-otp-main-con {
    margin-bottom: 30px;
}

.Forgot-mail-otp-con-details-Info-Input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.Forgot-mail-otp-con-details-Info-Input input {
    border: 1px solid #db7620;
    width: 35px !important;
    height: 35px !important;
    border-radius: 5px;
    margin: 0px 3px;
}

.Login-Section-Btn {
    text-align: center;
    margin: 20px 0px 0px 0px;
}

.Code-expires-con h4 {
    font-family: var(---Textfont01);
    font-size: 12px;
    font-weight: 400;
    margin: 0px;
}

@media (max-width: 768px) {
    .widthimage {
        display: none !important;
    }
}